<template>
  <div class="app-container">
    <eHeader  :query="query" :temptypeoptions="temptypeoptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="tempId" label="ID"/>
      <el-table-column prop="tempName" label="模板名称"/>
      <el-table-column prop="tempCode" label="模板编码"/>
      <el-table-column prop="tempContent" label="模板内容"/>
      <el-table-column prop="paramsNum" label="模板参数个数"/>
      <el-table-column prop="tempTypeName" label="模板类型">
        <template slot-scope="scope">
          <span>{{ temptypeoptions[scope.row.tempType].tempTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
          <el-popover
            v-if="checkPermission(['ADMIN','USER_ALL','USER_DELETE'])"
            :ref="scope.row.tempId"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.tempId].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="softDelete(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/sms/templateinfo/header'
import edit from '@/components/sms/templateinfo/edit'
import { updateTempInfo, listTempInfo } from '@/api/sms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      temptypeoptions: [{
        tempType: '0',
        tempTypeName: '营销类'
      }, {
        tempType: '1',
        tempTypeName: '通知类'
      }, {
        tempType: '2',
        tempTypeName: '验证码'
      }]
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/jrqsms/api/temp/listTempInfo'
      const query = this.query
      const tempName = query.tempName
      const tempCode = query.tempCode
      this.formData = { currentPage: this.page, pageSize: this.size }
      if (tempName !== '' && tempName !== null) { this.formData['tempName'] = tempName }
      if (tempCode !== '' && tempCode !== null) { this.formData['tempCode'] = tempCode } 
      this.params = JSON.stringify(this.formData)
      return true
    },
    softDelete(info) {
      this.delLoading = true
      info.status="1"
      this.formData = info
      this.params = JSON.stringify(this.formData)
      updateTempInfo(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.tempId].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[info.tempId].doClose()
        console.log(err.response.data.message)
      })
    }    
  }
}
</script>

<style scoped>

</style>
