<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增短信模板' : '编辑短信模板'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="模板编码" prop="tempCode">
        <el-input v-model="form.tempCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="模板名称" prop="tempName">
        <el-input v-model="form.tempName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="模板内容" prop="tempContent">
        <el-input v-model="form.tempContent" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="模板类型" prop="tempType">
        <el-select v-model="form.tempType" style="width: 500px;">
        <el-option
          v-for="item in temptypeoptions"
          :key="item.tempType"
          :label="item.tempTypeName"
          :value="item.tempType">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="模板参数个数" prop="paramsNum">
        <el-input v-model.number="form.paramsNum" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { insertTempInfo, updateTempInfo } from '@/api/sms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false, loading: false, form: {tempId: '',tempName: '', tempCode: '',tempContent: '', paramsNum: '', tempType: ''},
      rules: {
        tempCode: [
          { required: true, message: '请输入模板编码', trigger: 'blur' }
        ],
        tempName: [
          { required: true, message: '请输入模板名称', trigger: 'blur' }
        ],
        tempContent: [
          { required: true, message: '请输入模板内容', trigger: 'blur' }
        ],
        tempType: [
          { required: true, message: '请选择模板类型', trigger: 'blur' }
        ],
        paramsNum: [
          { required: true, message: '请输入模板参数个数', trigger: 'blur' },
          { type: 'number', message: '模板参数个数必须为数字值'}
        ],
      },
      temptypeoptions: [{
        tempType: '0',
        tempTypeName: '营销类'
      }, {
        tempType: '1',
        tempTypeName: '通知类'
      }, {
        tempType: '2',
        tempTypeName: '验证码'
      }]
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formData = this.form
      this.params = JSON.stringify(this.formData)
      insertTempInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formData = this.form 
      this.params = JSON.stringify(this.formData)
      updateTempInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {tempId: '',tempName: '', tempCode: '',tempContent: '', paramsNum: '', tempType: ''}
    }
  }
}
</script>

<style scoped>

</style>
